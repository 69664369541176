<template>
   <navi-bar>
     <img src="@/assets_mobile/img/headin_off.png"/>
     <span>출석부<em>출석부</em></span>
   </navi-bar>
  <section class="sub-box">
    <div class="calendarwrap mb100">
      <ul class="pnmonth">
        <li><a class="preMon" @click="moveMonth('prev')"><img src="@/assets/img/lefticon.png"></a></li>
        <li class="year_mon">{{currentYear}}년 {{currentMonth}}월</li>
        <li><a class="nextMon" @click="moveMonth('next')"><img src="@/assets/img/righticon.png"></a></li>
      </ul>

      <div class="calendarin">
        <ul class="weeke">
          <li v-for="(weekName, index) in weekNames" v-bind:key="index" :class="{'rd': index === 0}">
            {{weekName}}
          </li>
        </ul>
        <table class="calendar">
          <tbody>
          <tr v-for="(row, index) in currentCalendarMatrix" :key="index">
            <td v-for="(day, index2) in row" :key="index2" :class="{'colToday': isToday(currentYear, currentMonth, day), 'date': isCheck(currentYear, currentMonth, day)}">
              {{day}}
            </td>
          </tr>
          </tbody>
        </table>
        <p class="calendarday">{{currentYear}}년 {{currentMonth}}월은 총 <span class="bbl">{{attenList.length}}일</span> 출석 하였습니다.</p>
      </div>
      <div class="btnwrap">
        <a class="ok" @click="setAtten">출석체크하기</a>
      </div>
    </div>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import attendendance from '@/views/member/attendendance'

export default {
  name: 'attendance.vue',
  mixins: [attendendance],
  components: {
    NaviBar
  }
}
</script>
<style scoped>
.pnmonth {display: flex;justify-content: center;margin: 20px 0;}
.weeke {width: 100%;display: flex;justify-content: space-around;border: 1px solid #ddd;border-bottom: 0;text-align: center;align-items: center;padding: 10px 0;font-weight: bold;box-sizing: border-box;background: #fff;font-size: 16px;border-radius: 10px 10px 0 0;}
.year_mon {color: #0056a6;font-size: 20px;font-weight: bold;}
.pnmonth a img {height: 16px;vertical-align: middle;}
.preMon {margin-right: 15px;}
.nextMon {margin-left: 15px;}
.calendar td.colToday{color: #e35c54;}
.calendar td.on {background: url("~@/assets/img/stampRed.png") no-repeat !important;background-size: contain !important;background-position: center !important;}
.calendarin {box-shadow: 1px 0px 5px 1px #f0f0f0;border: 1px solid #e9e9e9;border-radius: 10px;padding: 30px 10px 0px;margin: 0 10px;background: #fafafa;}
.calendar {width: 100%;border :1px solid #ddd;background: #fff;font-weight: bold;border-radius: 0 0 10px 10px;}
.calendar tr, .calendar td {border :1px solid #ddd;}
.calendar td {height: 50px;padding: 10px 0 0 10px;}
.calendar td.date {background: url("~@/assets/img/stampGray.png") no-repeat;background-size: contain;background-position: center;font-size: 14px;}
.calendarday {padding: 10px 0;text-align: center;font-size: 14px;}
.calendarday span {font-weight: bold;}
</style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
